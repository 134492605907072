$dark-grey: #404040;
$nc-blue: #00BDFC;
$nc-bl-dark: #0093C4;
$red: #FC3F00;

button {
    border: none;
    border-radius: 0.25rem;
    //font-size: 0.75rem;
    font: 13px/1.5 "Helvetia Light","Helvetica Neue Light",Helvetica,Arial,sans-serif;
    //font-weight: bold;
    padding: 0.75em; // 12px/16px
    display: inline-block;
    text-decoration: none;
    
  
  }

.comment-form {
    background-color: #fff;
    border-radius: 0.25rem;
    margin-bottom: 2rem;
    padding: 1rem;  
  }
  .comment-form-fields {
    margin-bottom: 0.25rem;
    input, textarea {
      border: none;
      border-bottom: 1px solid #404040;
      font-size: 0.85rem;
      padding: 0.25rem 0;
      width: 99%;
      
      &:focus {
        border-bottom-color: $nc-blue;
        outline: none;
      }
    }
    textarea {
      font-style: italic;
    }
  }

  .comment-count {
    color: $dark-grey;
  }
  #comment-reveal {
    float: right;
    background-color: #3d6ed1;
    color: #fff;
  }
  .comment {
    border-top: 0.125rem solid $dark-grey;
  }
  .comment-list{
    overflow-y: scroll;
    height: 300px;
  }

  .comment-list-latest{
    overflow-y: scroll;
    height: 100%;
  }

  
  .comment-body {
    font-style: italic;
    margin-left: 1rem;
  }
  .comment-footer {
    margin-bottom: 1rem;
  }
  .comment-footer-delete {
    padding: 0.2rem 0;
    color: $dark-grey;
    text-decoration: none;
    
    &:hover {
      color: $nc-blue;
      font-weight: bold;
    }
  }
  
  .comment-box {
    max-width: 37.5rem;
    color: rgb(2, 0, 0);
    background-color: #f0f2f5;
    border-radius: 0.875rem;
    padding: 0.2rem 1rem 2rem;
  }