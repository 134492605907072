/** overwrite ag theme alphine */
.ag-theme-alpine{
  font-size: 12px!important;
}

.ag-theme-alpine .ag-header-cell, .ag-theme-alpine .ag-header-group-cell {
  padding-left: 10px!important;
  padding-right: 10px!important;
}

.ag-theme-alpine .ag-row {
  font-size: 11.5px!important;
}

.ag-theme-alpine .ag-cell, .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  line-height: 15px!important;
  padding:10px;
}

.ag-theme-alpine .ag-paging-panel > * {
  margin: 0 5px!important;
}

.ag-theme-alpine .ag-paging-panel {
  border:none;
  height: 55px;
}

.ag-paging-button, .ag-paging-description, .ag-paging-row-summary-panel{
  span{
    margin-right: 4px;
  }
}

.ag-react-container{
  input{
    width: 80%;
    border-color: #c1c1c1;
    border-radius: 5px;
    padding: 5px;
  }
}

.ag-theme-alpine .ag-paging-panel {
  display: flex;
  position: fixed;
  bottom: 0px;
  right: 0;
  margin: 6px 0;
  border-top: none!important;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 -1px;
  padding: 0;
}

.ag-theme-alpine .ag-ltr .ag-header-cell:not(.ag-right-aligned-header) .ag-header-label-icon {
  margin-left: 0!important;
}
.ag-react-container input {
  border: 1px solid #e1e1e6;
}

.ag-custom-description{
  white-space: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ag-react-container select {
  border: 1px solid #e1e1e6;
}

 .ag-react-container select {
   width: 80%;
   border-color: #c1c1c1;
   border-radius: 5px;
   padding: 5px;
 }