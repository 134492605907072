a, body, div, header, html, p, span {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

body, html{
  height: 100%;
}

body {
  min-width: 1024px;
  font: 12px/1.5 "Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
  color: #353535;
  background: #fff;
}

header {
  display: block;
}

a {
  text-decoration: none;
  color: #353535;
}

header {
  height: 60px;
  border-bottom: 1px solid #ebebeb;
  background-color: #f1f1f1;
}

.toolbar {
  height: 60px;
  border-bottom: 1px solid #ebebeb;
  background-color: #f1f1f1;
}

.toolbar .toolbar-container {
  display: flex;
  align-content: center;
} 

.toolbar .toolbar-container .logo {
  margin: 8px 0 0 25px;
  background: url(images/sprite/logo.png) no-repeat;
  /* position: fixed; */
  height: 60px;
  background-size: contain;
  width: 100px;
  /* background-position: center; */
  cursor: pointer;
  line-height: 0; 
  font-size: 0;
  color: transparent;
}

.toolbar .toolbar-container .toolbar-title {
  font-size: 20px;
  font-weight: 700;
  /* bolder; */
  height: 60px;
  line-height: 60px;
  cursor: default;
}

.fl {
  float: left;
}

.fr{
  float: right;
}

.toolbar .toolbar-container .spacer {
  flex : 1;
}

.toolbar .toolbar-container .login-info {
  height: 32px;
  margin: 14px 15px 0 0;
  line-height: 32px;
}

.login-info .btn-logout {
  margin-left: 10px;
  padding: 0 8px;
  border: 1px solid #c6c6c6;
  border-radius: 2px;
  line-height: 28px;
  background: #f1f1f1;
  color: #666;
  cursor: pointer;
}

.menubar {
  height: 57px;
  line-height: 57px;
  border-bottom: 1px solid #ebebeb;
  background-color: #fff;
}

.menubar .menu-title {
  position: relative;
  width: 240px;
  height: 100%;
}

.menubar .menu-title a {
  display: block;
  padding-left: 35px;
  height: 100%;
  text-decoration: none;
  font-size: 16px;
  color: #1e1e1e;
}

.menubar .menu-title a .sprite-more {
  display: block;
  margin: 22px 15px 0 0;
}

.menubar .menu-title .menu-list {
  position: absolute;
  left: -240px;
  top: 0;
  z-index: 100;
  width: 100%;
  background: rgba(0,0,0,.8);
  transition: .15s linear left;
  height: 0;
  overflow: hidden;
  margin: 0;
  padding-inline-start: 0;
}

.menubar .menu-title .menu-list.menu-list-expanded {
  left: 0;
  height: auto;
}

/* 
.menubar .menu-title .menu-list {
  margin: 0;
  padding-inline-start: 0;
} */

.menubar .menu-title .menu-list .menu-home a {
  display: block;
  border-bottom: 1px solid #616161;
  color: #fff;
}

.menubar .menu-title .menu-list .menu-home a:hover {
  background: rgba(0,0,0,.9);
}

.menubar .menu-title .menu-list .menu-item a {
  display: block;
  height: 60px;
  line-height: 60px;
  font-size: 12px;
  color: #fff;
}

.menubar .menu-title .menu-list .menu-item a:hover {
  background: rgba(0,0,0,.9);
}

.menubar .menu-title .menu-list .menu-item a span {
  margin: 16px 20px 0 0;
}

.content {
  /* position: fixed; */
  height: calc(100% - 120px);
  width: 100%;
  /* background: rgba(218, 214, 214, 0.8);
  z-index: 100; */
  /* border-bottom: 1px solid #ebebeb; */
}

.content .menu-list {
  padding: 70px 0 0 160px;
}

.content .menu-list li {
  float: left;
  width: 220px;
  height: 175px;
  margin: 0 100px 50px 0;
  text-align: center;
  font-size: 12px;
}

.content .menu-list li .menu-icon {
  display: inline-block;
}

.content .menu-list li .menu-icon:hover { 
  opacity: .9;
}

.content .menu-list li .menu-name {
  display: block;
  margin: 18px 0 6px;
}

.content .menu-list li .menu-name a {
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
  color: #282828;
}

.content .menu-list li .menu-name a:hover {
  text-decoration: underline;
}

.content .sub-page{
  position:relative;
  z-index:10
}

.content .checkListWrapper{
  padding:30px;
  background-color:#f8f8f8
}
.content .checkListWrapper .checkList{
  position:relative
}

.sprite-fscp-s, .sprite-others-s, .sprite-gcr-s,.sprite-more ,.sprite-fscp, .sprite-others, .sprite-gcr,
.sprite-hq-input, .sprite-hq-input-s{
  background: url(images/sprite/sprite_01.png) no-repeat;
}

.sprite-hq-input {
  background-position: 0 -540px;
  height: 81px;
  width: 81px;
}

.sprite-hq-input-s {
  background-position: 0 -509px;
  height: 26px;
  width: 26px;
}

.sprite-more {
  background-position: 0 -3499px;
  height: 13px;
  width: 18px;
}

.sprite-more.more-active, .sprite-more:active {
  background-position: 0 -3481px;
}

.sprite-fscp-s {
  /* background-position: 0 -4099px; */
  background-position: 0px -4783px; /*cakrab*/
  height: 26px;
  width: 26px;
}

.sprite-others-s {
  background-position: 0 -667px;
  height: 26px;
  width: 26px;
}

.sprite-gcr-s {
  background-position: 0 -508px;
  height: 36px;
  width: 36px;
}

.sprite-fscp {
  background-position: 0 -4814px; /*cakrab */
  height: 81px;
  width: 81px;
}

.sprite-others {
  background-position: 0 -696px;
  height: 80px;
  width: 88px;
}

.sprite-gcr {
  background-position: 0 -4245px;
  height: 81px;
  width: 81px;
}

/*cakrab*/
.fscp-detail-wrapper {
  display:flex;
  justify-content: left; 
}
/*cakrab*/


/*cakrab*/
.fscp-detail-wrapper .fscp-detail-btn {
  border-radius: 5px;
  width: 40px; /*cakrab*/
  height: 20px; /*cakrab*/
  color: #fff; /*cakrab*/
  background-color: #3d6ed1;/*cakrab*/
  border: 0;/*cakrab*/
  font: 12px "Helvetia Light","Helvetica Neue Light",Helvetica,Arial,sans-serif; /*cakrab*/
  border: 0; /*cakrab*/
  margin: 0.5%;/*cakrab*/
  margin-top: 3%;/*cakrab*/
}

/*cakrab*/
.fscp-detail-attach-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
/*cakrab*/

/*cakrab*/
.fscp-detail-attach-wrapper .fscp-detail-attach-btn {
  border-radius: 5px;
  width: 50px; /*cakrab*/
  height: 20px; /*cakrab*/
  color: #fff; /*cakrab*/
  background-color: #3d6ed1;/*cakrab*/
  border: 0;/*cakrab*/
  font: 12px "Helvetia Light","Helvetica Neue Light",Helvetica,Arial,sans-serif; /*cakrab*/
  border: 0; /*cakrab*/
  margin: 0.5%;/*cakrab*/
  margin-top: 3%;/*cakrab*/
}

.fscp-detail-attach-wrapper .fscp-detail-attach-btn-disabled {
  border-radius: 5px;
  width: 50px; /*cakrab*/
  height: 20px; /*cakrab*/
  color: #fff; /*cakrab*/
  background-color: #3d6ed1;/*cakrab*/
  border: 0;/*cakrab*/
  font: 12px "Helvetia Light","Helvetica Neue Light",Helvetica,Arial,sans-serif; /*cakrab*/
  border: 0; /*cakrab*/
  margin: 0.5%;/*cakrab*/
  margin-top: 3%;/*cakrab*/
  opacity: 0.8;
}
/*cakrab*/
/*cakrab*/
/* .fscp-detail-attach-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
} */
/*cakrab*/


.content .fscp-wrapper, .content .fscpdetail-wrapper{
  position: relative;
  margin: 16px;
  /* padding: 0 10px 10px 10px; */
  height: calc(100% - 32px);
  width: calc(100% - 32px); 
  /* border: 1px solid #d6d6d6; */
  border-radius: 5px;
  /* box-shadow: -1px 1px 4px #e4e4e4; */
  box-sizing: border-box;
}

.content .fscp-wrapper #title, .content .fscpdetail-wrapper #title {
  text-align: left;
  font-weight: 700;
  /* font-size: 14px; */ /*cakrab*/
  font: 14px/1.5 "Helvetia Light","Helvetica Neue Light",Helvetica,Arial,sans-serif; /*cakrab*/
  color: #000;/*cakrab*/
}

.content .fscp-wrapper .fscp-search {
  /*position: relative;
  padding: 16px 0px 16px 0px;
  height: 72px;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  box-shadow: -1px 1px 4px #e4e4e4;
  box-sizing: border-box;   
  display:flex;
  margin-left: 2px;*/
  /*cakrab*/
  display: flex;/*cakrab*/
  padding: 2px;/*cakrab*/
  border-radius: 2px;/*cakrab*/
  font: 14px/1.5 "Helvetia Light","Helvetica Neue Light",Helvetica,Arial,sans-serif; /*cakrab*/
  font-weight: bolder;
}

.content .fscp-wrapper .fscp-btn-wrapper, .content .fscpdetail-wrapper .fscpdetail-btn-wrapper {
  /*margin-left: 25px;*//*cakrab*/
  justify-content:flex-end;
  display:flex;
  margin-left: 20px;/*cakrab*/
  margin-top: 2px;/*cakrab*/
}

.content .fscp-wrapper .fscp-btn-wrapper .fscp-btn {
  border-radius: 5px;/*cakrab*/
  /*width: 76px;
  height: 38px;*/
  margin: 4px 0px 0px;  /*cakrab*/
  color: #fff;/*cakrab */
  background-color: #3d6ed1;/*cakrab*/
  height: 28px;/*cakrab*/
  width: 100px;/*cakrab*/
  cursor: pointer;/*cakrab*/
  line-height: 30px;/*cakrab*/
  font: 14px/1.5 "Helvetia Light","Helvetica Neue Light",Helvetica,Arial,sans-serif; /*cakrab*/
  padding: 0px; /*cakrab*/
  border: 0; /*cakrab*/
  margin-left: 8px;/*cakrab*/
  margin-right: 10px;/*cakrab*/
}

.content .fscp-wrapper .fscp-btn-wrapper .fscp-btn-report {
  border-radius: 5px;/*cakrab*/
  /*width: 76px;
  height: 38px;*/
  margin: 4px 0px 0px;  /*cakrab*/
  color: #fff;/*cakrab */
  background-color: #3d6ed1;/*cakrab*/
  height: 28px;/*cakrab*/
  width: 200px;/*cakrab*/
  cursor: pointer;/*cakrab*/
  line-height: 30px;/*cakrab*/
  font: 14px/1.5 "Helvetia Light","Helvetica Neue Light",Helvetica,Arial,sans-serif; /*cakrab*/
  padding: 0px; /*cakrab*/
  border: 0; /*cakrab*/
  margin-left: 8px;/*cakrab*/
  margin-right: 10px;/*cakrab*/
}

.content .fscp-wrapper .fscp-btn-wrapper .fscp-btn-disabled{
  border-radius: 5px;/*cakrab*/
  /*width: 76px;
  height: 38px;*/
  margin: 4px 0px 0px;  /*cakrab*/
  color: #fff;/*cakrab */
  background-color:#3d6ed1;/*cakrab*/
  opacity: 0.6;
  height: 28px;/*cakrab*/
  width: 100px;/*cakrab*/
  cursor: pointer;/*cakrab*/
  line-height: 30px;/*cakrab*/
  font: 14px/1.5 "Helvetia Light","Helvetica Neue Light",Helvetica,Arial,sans-serif; /*cakrab*/
  padding: 0px; /*cakrab*/
  border: 0; /*cakrab*/
  margin-left: 8px;/*cakrab*/
  margin-right: 10px;/*cakrab*/
}

.content .fscpdetail-wrapper .fscpdetail-btn-wrapper .fscpdetail-btn {
  border-radius: 5px;
  width: 76px;
  height: 38px;
  margin: 20px 15px 0 15px;
  color: #fff;/*cakrab*/
  background-color: #3d6ed1;/*cakrab*/
  padding: 0px; /*cakrab*/
  border: 0; /*cakrab*/
}

.content .fscpdetail-wrapper .fscpdetail-btn-wrapper .fscpdetail-btn-disabled {
  border-radius: 5px;
  width: 76px;
  height: 38px;
  margin: 20px 15px 0 15px;
  color: #fff;/*cakrab*/
  background-color: #3d6ed1;/*cakrab*/
  opacity: 0.6;
  padding: 0px; /*cakrab*/
  border: 0; /*cakrab*/
}

.content .fscp-wrapper .fscp-summary {
  margin: 12px 0 12px 0;
  position: relative;
  height: calc(100% - 16px);
}

.content .fscp-wrapper .fscp-summary {
  width: 100%;
  border-radius: 3px;
}

.content .fscp-wrapper .fscp-summary a {
  text-decoration: none;
}

.content .fscp-wrapper .fscp-summary a:hover {
  text-decoration: none;
  border-bottom: 1px solid blue;
}

.content .fscpdetail-wrapper .fscpdetail-params-container {
  display: flex;
}

.content .fscpdetail-wrapper .fscpdetail-params-container div{
  flex: 1;
}

.content .fscpdetail-wrapper .fscpdetail-params-container {
  position: relative;
  padding: 5px 0px 10px 0px;
  height: 100px;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  box-shadow: -1px 1px 4px #e4e4e4;
  box-sizing: border-box; 
  margin-left: 2px;
}

.content .fscpdetail-wrapper .fscpdetail-params-container .fscpdetail-params {
  margin-left: 10px;
  font-size:14px;
  display: flex;
  flex-direction: column;
}

.content .fscp-detail {
  margin-left: 16px;
  width: calc(100% - 32px);
  border: 0;
}


.fscp-history-wrapper {
  display:flex;
  justify-content: center; 
}

.fscp-history-wrapper .fscp-history-btn {
  border-radius: 5px;
  width: 76px;
  height: 38px;
  color: #fff; /*cakrab*/
  background-color: #3d6ed1;/*cakrab*/
  border: 0;/*cakrab*/
}

/*cakrab*/
.fscp-showhistory-btn {
  display: block; 
  justify-content: center;
  border-radius: 5px;
  width: 100px;
  height: 24px;
  color: #fff; /*cakrab*/
  background-color: #3d6ed1;/*cakrab*/
  border: 0;/*cakrab*/
  margin: auto;
  position:relative;
}
/*cakrab*/

.fscp-updstat {
  margin-right: 15px;
  display: flex;
  justify-content: center;
}

/*cakrab*/
.fscp-updstat-btn {
  display: block; 
  justify-content: center;
  border-radius: 5px;
  width: 100px;
  height: 24px;
  color: #fff; /*cakrab*/
  background-color: #3d6ed1;/*cakrab*/
  border: 0;/*cakrab*/
  margin: auto;
  position:relative;
}
/*cakrab*/

/*cakrab*/
.fscp-updstat-btn-disabled {
  display: none; 
  justify-content: center;
  border-radius: 5px;
  width: 100px;
  height: 24px;
  color: #fff; /*cakrab*/
  background-color: #3d6ed1;/*cakrab*/
  opacity: 0.6;
  border: 0;/*cakrab*/
  margin: auto;
  position:relative;
}
/*cakrab*/

.fscp-updstat-btn-wrapper, .fscp-createalert-wrapper {
  display:flex;
  justify-content: center;
}

.fscp-updstat-btn-wrapper .fscp-updstat-btn, .fscp-createalert-wrapper .fscp-createalert-btn {
  margin: 0 10px 0 10px;
  border-radius: 5px;
  width: 72px;
  height: 38px;
  color: #fff; /*cakrab*/
  background-color: #3d6ed1;/*cakrab*/
  border: 0;/*cakrab*/
}

.attachment-logos {
  /* margin: 8px 0 0 25px; */
  /* background: url(images/sprite/logo.png) no-repeat; */
  /* position: fixed;*/
  height: 45px;
  width: 45px;
  cursor: pointer;
  line-height: 0; 
  font-size: 0;
  color: transparent;
  cursor: pointer 
}

#content-editable{
  -moz-user-select: text;
  -moz-user-modify: read-write;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -o-user-select: text;
  }

.table-createsubmission{
  border: 2px black solid;
  width: 100%;
  height: 100%;
}

.leftbox{ 
  float:left;  
  align-content: center;
  /* background:Red;  */
  width:75%; 
  height:100%; 
}

.middlebox{ 
  float:left;  
  /* background:Green;  */
  width:25%; 
  height:100%; 
}

.rightbox{ 
  float:right; 
  /* background:blue;  */
  width:25%; 
  height:100%; 
} 

.leftbox-inside{ 
  float:left;  
  /* background:Red;  */
  padding-left: 50px;
  width:30%; 
  /* height:280px;  */
  border: 1px solid;

}

.middlebox-inside{ 
  float:right;  
  /* background:Green;  */
  width:30%; 
  /* height:80%;  */
  border: 1px solid;
}

.rightbox-inside{ 
  float:right; 
  /* background:blue;  */
  width:30%; 
  /* height:280px;  */
  border: 1px solid;

}

.row {
  display: flow-root;
}


.column {
  float: left;
  width: 33.33%;
  border: 0px solid;
}

.column-modal {
  float: left;
  width: 35%;
  border: 0px solid;
}

.column-modal-right {
  float: right;
  width: 100%;
  border: 0px solid;
}

.input{
  padding-bottom: 15%;
  padding-left: 10%;
  height: 30px;
}



.input-extra{
  padding-top: 30%;
  padding-left: 10%;
  /* padding-bottom: 5%; */
}

.input-below{
  padding-top: 15%;
  padding-left: 10%;
  /* padding-bottom: 5%; */
}

.input-history{
  padding-top: 15%;
  padding-left: 3%;
}

.label{
  font-size: 15px;
  font-weight: bolder;
}

.inputstyle-left-right{
  white-space: pre;
  display: block;
  /* padding-left: 100px; */
  width: 75%;
  font-size: inherit;
  line-height: 1.25;
  color: #55595c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid;
  border-left: 0;
}

.inputstyle-date{
  white-space: pre;
  display: block;
  /* padding-left: 100px; */
  padding-top: 5%;
  width: 130%;
  font-size: inherit;
  line-height: 1.25;
  color: #55595c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid;
  border-left: 0;
  height:25px;
  font-size: 15px;
}

.inputstyle-centre{
  white-space: pre;
  display: block;
  padding-left: 100px;
  width: 50%;
  font-size: 1rem;
  line-height: 1.25;
  color: #55595c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid;
  border-left: 0;
}

.selectstyle{
  background-position: center right;
  background-repeat: no-repeat;
  border: 1px solid #AAA;
  border-radius: 50px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  color: #555;
  font-size: inherit;
  margin: 0;
  overflow: hidden;
  padding-top: 2px;
  padding-bottom: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 30px;
}

.attachmentstyle{
  border: 1px solid #AAA;
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  color: #555;
  font-size: inherit;
  margin: 0;
  overflow: hidden;
  padding-top: 2px;
  padding-bottom: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.numericstyle{
  border-radius: 4px;
}

.flow-detail-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
}

.flow-detail-button {
  display: flex;
  padding: 3px;
  background: #e5e5e5;
  color: #fff;
  background-color: #3d6ed1;
  height: 25px;
  width: 100px;
  cursor: pointer;
  font: 12px/1.5 "Helvetia Light","Helvetica Neue Light",Helvetica,Arial,sans-serif; 
  border: 0;
  border-radius: 5px;
  float:right;
  line-height: 17px;
  justify-content:center;
  margin: 5px;
}

.flow-detail-button:hover {
  background-color: #1f66f3;
}

.ReactModal__Overlay {
  opacity: 0;
  transform: translateX(-100px);
  transition: all 800ms ease;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateX(-100px);
}


.approval-logos {
  /* margin: 8px 0 0 25px; */
  /* background: url(images/sprite/logo.png) no-repeat; */
  /* position: fixed;*/
  position: relative;
  /* height: 100%; */
   width: 50%;
  cursor: pointer;
  line-height: 0; 
  font-size: 0;
  color: transparent; 
}

.table-flow {
  /* margin: 8px 0 0 25px; */
  /* background: url(images/sprite/logo.png) no-repeat; */
  /* position: fixed;*/
  position: relative;
  /* height: 100%; */
   width: 100%;
  border: black 1px solid;
}

.td-flow {
  /* margin: 8px 0 0 25px; */
  /* background: url(images/sprite/logo.png) no-repeat; */
  /* position: fixed;*/
  border: black 1px solid;
}

.td-image{
  width: 40%;
}

.td-emaildate{
  word-break: break-all;
  width: 60%;
}

.tr-date{
  font-weight: normal;
  color: gray;
}

.arrow-logos {
  /* margin: 8px 0 0 25px; */
  /* background: url(images/sprite/logo.png) no-repeat; */
  /* position: fixed;*/
  height: 20px;
   width: 25px;
  cursor: pointer;
  line-height: 0; 
  font-size: 0;
  color: transparent; 
}

.flow-container{
  position: relative;
  text-align: center;
  padding: 10px 10px 10px 10px;
  inline-size: inherit;
  font-size: 11px;
  /* border: black solid; */
  /* border-style: dotted;
  border: 1px solid black; */
  
}

.flow-container-summary{
  position: relative;
  text-align: center;
  padding: 10px 10px 10px 10px;
  inline-size: inherit;
  font-size: 9px;
  /* border: black solid; */
  /* border-style: dotted;
  border: 1px solid black; */
  
}

.content .flow-list {
  padding: 30px 10px 20px 20px;
  /* height: 50px; */
  height: 400px;
  width: auto;
  position: relative;
  text-align: center;
  overflow: scroll;
  overflow-x: hidden;
  border:beige solid;
  border-radius: 10px;
}

.content .flow-list-summary {
  /* padding: 30px 10px 20px 20px; */
  padding-left: 5%;
  /* height: 50px; */
  height: 400px;
  width: 95%;
  position: relative;
  text-align: center;
  /* overflow: scroll;
  overflow-x: hidden; */
  /* border:beige solid;
  border-radius: 10px; */
}


.modal-close-button{
  display: flex;
  padding: 3px;
  background: #e5e5e5;
  color: #fff;
  background-color: #3d6ed1;
  height: 25px;
  width: 25px;
  cursor: pointer;
  font: 12px/1.5 "Segoe UI", "Roboto", "Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue", "sans-serif !default";
  border: 0;
  border-radius: 5px;
  float:right;
  text-align: center;
  line-height: 15px;
  justify-content:center;
  position: absolute;
  right: 0;
  margin-right: 20px;
  border:0px;
  font-weight: bold;
}


.account-balance-detail-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
}

.account-balance-detail-button {
  display: flex;
  padding: 3px;
  background: #e5e5e5;
  color: #fff;
  background-color: #3d6ed1;
  height: 25px;
  width: 100px;
  cursor: pointer;
  font: 12px/1.5 "Helvetia Light","Helvetica Neue Light",Helvetica,Arial,sans-serif; 
  border: 0;
  border-radius: 5px;
  float:right;
  line-height: 17px;
  justify-content:center;
  margin: 5px;
}

.account-balance-detail-button-disabled {
  display: flex;
  padding: 3px;
  background: #e5e5e5;
  color: #fff;
  opacity: 0.6;
  background-color: #3d6ed1;
  height: 25px;
  width: 100px;
  cursor: pointer;
  font: 12px/1.5 "Helvetia Light","Helvetica Neue Light",Helvetica,Arial,sans-serif; 
  border: 0;
  border-radius: 5px;
  float:right;
  line-height: 17px;
  justify-content:center;
  margin: 5px;
}

.account-balance-detail-label{
  font: 12px/1.5 "Segoe UI", "Roboto", "Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue", "sans-serif !default";
  font-weight: bold;
}

.input-field-right{
  height: 10px;
  background-color: white;
  border: 0;
  font: 12px/1.5 "Helvetia Light","Helvetica Neue Light",Helvetica,Arial,sans-serif;
  font-weight: bold;  
  color: black;
  text-align: right;
  width: 100px;
}

.input-field-left{
  height: 10px;
  background-color: white;
  border: 0;
  font: 12px/1.5 "Helvetia Light","Helvetica Neue Light",Helvetica,Arial,sans-serif;
  color: black;
  text-align: left;
}


.label-field-right{
  background-color: white;
  border: 0;
  font: 12px/1.5 "Helvetia Light","Helvetica Neue Light",Helvetica,Arial,sans-serif; 
  color: black;
  display:block; 
  width:70px; 
  text-align:right;
  display: inline-block;
  margin-right: 5px;
}

.label-field-left{
  height: 10px;
  background-color: white;
  border: 0;
  font: 12px/1.5 "Helvetia Light","Helvetica Neue Light",Helvetica,Arial,sans-serif;
  color: black;
  text-align: right;
}

.Overlay {
  position: fixed;
  top: 200px;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: white; */
  opacity:1%,
}

.input-field-left-percent{
  height: 15px;
  width: 45px;
  background-color: #ffeb3b4d;
  border: 1px solid #BBB;
  font: 13px/1.5 "Helvetia Light","Helvetica Neue Light",Helvetica,Arial,sans-serif;
  color: black;
  text-align: left;
}


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.asterisk{
  font-size: small;
  color: red;
}

.asterisk-bottom{
  font-size: small;
  color: red;
  vertical-align: bottom;
}

.asterisk-super{
  font-size: small;
  color: red;
  vertical-align: super;
}

.ReactTable .rt-resizable-header {
  padding: 6px 24px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ReactTable .rt-resizer:before {
  display: inline-block;
  position: absolute;
  right: 25px;
  top: 3px;
  height: 18px;
  width: 18px;
  color: transparent;
  content: '.';
  background-size: 18px 18px;
  background-repeat: no-repeat; 
  opacity: 0.87;
}

.ReactTable  .rt-resizable-header-content:after {
  position: absolute;
  right: 8px;
  top: 3px;
  height: 18px;
  width: 18px;
  z-index: 120;
  color: transparent;
  content: '.';
}

.ReactTable .rt-th.-sort-asc .rt-resizer:before {
  background-image: url(images/sprite/sort-down.png);
}

.ReactTable .rt-th.-sort-desc .rt-resizer:before {
  background-image: url(images/sprite/sort-up.png);
}

.hyperlink{
  font: 12px/1.5 "Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
  text-decoration: underline;
  font-style: italic;
  color: royalblue;
}

.deleteIcon{
  list-style-image: url(images/sprite/delete-15.png);
}

.attachIcon{
  cursor: pointer;
}

.font-blue{
  color: blue;
  cursor: pointer;
}

.ag-header-cell-label {
  justify-content: center;
}

.span-gray{
  color: lightslategray;
}

.button-warning{
  border-radius: 5px;
  width: 70px;
  height: 30px;
  margin: 5px 0px 10px 0px;
  color: #fff;/*cakrab*/
  background-color: #3d6ed1;/*cakrab*/
  padding: 0px; /*cakrab*/
  border: 0; /*cakrab*/
  cursor: pointer;
  margin-right: 5px;
  float: right;
}

.btn-focus{
  background-color: #1f345c!important;
  color: white!important;
}

.btn-neutral{
  background: rgb(241 241 241)!important;
  color: black!important;
}

/* loading dots */
.loading{
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,sans-serif;
  font-size: 13px;
  border: 1px solid #babfc7;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 4px 1px rgb(186 191 199);
  padding: 6px;
}

.loading:after {
  padding-right: 6px;
  content: '.';
  animation: dots 1s steps(5, end) infinite;}

@keyframes dots {
  0%, 20% {
    color: rgba(0, 0, 0, 0.5);
    text-shadow:
            .25em 0 0 rgba(0, 0, 0, 0.5),
            .5em 0 0 rgba(0, 0, 0, 0.5);
  }
  40% {
    color: white;
    text-shadow:
            .25em 0 0 rgba(0, 0, 0, 0.5),
            .5em 0 0 rgba(0, 0, 0, 0.5);
  }
  60% {
    text-shadow:
            .25em 0 0 black,
            .5em 0 0 rgba(0, 0, 0, 0.5);
  }
  80%, 100% {
    text-shadow:
            .25em 0 0 black,
            .5em 0 0 black;
  }
}

.export-react-table{
  display:none
}